.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* FOR SLICK */
.vertical-dots
{
    right:10px;
    list-style: none;
    display: block;
    position: absolute;
    top: 40%;
    margin-top: -10px;
    text-align: right;
}
.vertical-dots li
{
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.vertical-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.vertical-dots li button:hover,
.vertical-dots li button:focus
{
    outline: none;
}
.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before
{
    opacity: 1;
}
.vertical-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.vertical-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
